<template>
  <div class="full_popup top-env-padding d-md-none">
    <MobileSteps :wizardSteps="wizardSteps" :page="page" />
    <div class="row mx-0">
      <div class="col-12 p-0 h-100 ">
        <div class="row mx-0 justify-content-center">
          <StepCount :page="page" :totalPages="totalPages" />
        </div>
      </div>
    </div>
    <ThePage>
      <slot />
    </ThePage>
    <Footer v-if="!isOpenOverlay" @goBack="back" @goNext="next" @save="save" :page="page" :totalPages="totalPages" :saveDisabled="saveDisabled" :isLoading="isLoading" :nextDisabled="nextDisabled" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    ThePage: defineAsyncComponent(() => import('./ThePage.vue')),
    Footer: defineAsyncComponent(() => import('../views/footer/Footer.vue')),
    MobileSteps: defineAsyncComponent(() => import('./MobileSteps.vue')),
    StepCount: defineAsyncComponent(() => import('./StepCount.vue'))
  },
  computed: {
    ...mapGetters(['isOpenOverlay'])
  },
  props: ['page', 'totalPages', 'nextDisabled', 'saveDisabled', 'isLoading', 'wizardSteps'],
  emits: ['goBack', 'goNext', 'save'],
  methods: {
    back () {
      this.$emit('goBack')
    },
    next () {
      this.$emit('goNext')
    },
    save () {
      this.$emit('save')
    }
  }
}
</script>
<style>
  .full_popup {
    position: fixed;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgb(249, 250, 251);
    padding: 0;
  }
</style>
